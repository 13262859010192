import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasks: [],
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasks(state, action) {
      state.tasks = action.payload;
      return state;
    },
    setAnswerIndex(state, action) {
      const { questionIndex, answerIndex } = action.payload;
      console.log({ questionIndex });
      state.tasks[0].questions[questionIndex].answerIndex = answerIndex;
      return state;
    },
    deleteTask(state, action) {
      console.log({ action });
      const updatedTasks = state.tasks.map((task) => {
        if (task.id !== action.payload) {
          return task;
        }
      });
      console.log({ updatedTasks });
      state.tasks =
        updatedTasks[0] === null || updatedTasks[0] === undefined
          ? []
          : updatedTasks;
      return state;
    },
  },
});

export const { setTasks, setAnswerIndex, deleteTask } = taskSlice.actions;

export default taskSlice.reducer;
