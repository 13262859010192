import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
import {
  setAlertsToSeen,
  setUnseenAlertCount,
  setAlerts,
  addOneAlert,
} from "@/store/reducers/alertSlice";
import {
  setSocket,
  addOneMessage,
  setMessages,
} from "@/store/reducers/socketSlice";
import {
  setUser,
  setSpouse,
  addChild,
  getChildren,
  addAllActivities,
  addAllAppointments,
  addAllCustody,
} from "@/store/reducers/userSlice";
import { setGameData } from "@/store/reducers/gameSlice";

import { getSocket } from "@/api/socket";
import { getToken, getUser, setToken } from "@/api/storage";
import { get, post } from "@/api/index";
import { ProtectedRoute, AdminRoute } from "@/utils/Components";
import { compose } from "redux";
import AlertMessage from "@/components/alertMessage";
import Loader from '@/components/loader'

const SplashIntro = lazy(() =>
  import("@/pages/splashIntro/index" /* webpackChunkName: "SplashIntro" */)
);
const SplashMenu = lazy(() =>
  import("@/pages/splashMenu/index" /* webpackChunkName: "SplashMenu" */)
);
const SurveyPage = lazy(() =>
  import("@/pages/survey/index" /* webpackChunkName: "Survey" */)
);
const MessagePage = lazy(() =>
  import("@/pages/message/index" /* webpackChunkName: "Message" */)
);
const CalendarPage = lazy(() =>
  import("@/pages/calendar/index" /* webpackChunkName: "Calendar" */)
);
const ActivityPage = lazy(() =>
  import("@/pages/activity/index" /* webpackChunkName: "Activity" */)
);
const AppointmentsPage = lazy(() =>
  import("@/pages/appointments/index" /* webpackChunkName: "Appointments" */)
);
const CustodyPage = lazy(() =>
  import("@/pages/custody/index" /* webpackChunkName: "Custody" */)
);
const FamilyPage = lazy(() =>
  import("@/pages/family/index" /* webpackChunkName: "Family" */)
);
const ParentProfilePage = lazy(() =>
  import(
    "@/pages/family/components/individualProfile/index" /* webpackChunkName: "ParentProfile" */
  )
);
const ChildProfilePage = lazy(() =>
  import(
    "@/pages/family/components/childProfile/index" /* webpackChunkName: "ChildProfile" */
  )
);
const LoginPage = lazy(() =>
  import("@/pages/login/index" /* webpackChunkName: "Login" */)
);
const GamesPage = lazy(() =>
  import("@/pages/games/index" /* webpackChunkName: "Games" */)
);
const AlertsPage = lazy(() =>
  import("@/pages/alerts/index" /* webpackChunkName: "Alerts" */)
);
const AdminPanel = lazy(() =>
  import("@/pages/adminPanel/index" /* webpackChunkName: "AdminPanel" */)
);
const PrivacyPolicy = lazy(() =>
  import("@/pages/policy/index" /* webpackChunkName: "PrivacyPolicy" */)
);

const Root = () => {
  const lastLocation = useLastLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user.userData);
  const socket = useSelector(({ socket }) => socket.socket);
  const children = useSelector(({ user }) => user.children);

  const fireWhenRouteChanges = (previousPath, currentPath) => {
    if (previousPath === "/alerts") {
      dispatch(setAlertsToSeen());
      dispatch(setUnseenAlertCount());
    }
  };

  const fetchFamily = () => {
    get("user/family")
      .then(({ data }) => {
        dispatch(setSpouse(data));
      })
      .catch((err) => {
        console.log("there was an error fetching family");
        console.log(err.message);
      });
  };

  // Getting Game State On Load
  const getGameState = async () => {
    try {
      const gameStateRes = await get("game/one");

      if (gameStateRes?.data) {
        const { data } = gameStateRes;
        const newGameState = { ...data, state: JSON.parse(data.state) };
        dispatch(setGameData(newGameState));
      }
    } catch (err) {
      // console.log(err);
    }
  };

  //----------------------------------------------
  //The effect function(first parameter) would be fired anytime any of the variables in the dependency array(second parameter) changes
  //----------------------------------------------
  useEffect(() => {
    const currentLocationPath = history.location.pathname;
    const lastLocationPath = lastLocation?.pathname || "/"; //If we are just loading app then last location is actually null, hence default it to route "/"

    fireWhenRouteChanges(lastLocationPath, currentLocationPath);
  }, [history.location]);

  /**
   * Get all teh activities of the children
   */
  useEffect(() => {
    const childrenIds = children.map(({ id }) => id);
    // add 'default' as an element to the array, this would be responsible for getting the activities of the parent swithout children
    childrenIds.push('default');
    // add 'default' as an element to the array, this would be responsible for getting the activities of the parent swithout children
    const childrenActivities = childrenIds.map(async (childId) => {
      const { data: response } = await get(`family/activities/${childId}`);
      return response;
    });

    Promise.all(childrenActivities).then((actualActivities) => {
      // console.log({ actualActivities });
      dispatch(addAllActivities(actualActivities));
    });
  }, [children]);

  /**
   * Get all the appointments of a child
   */
  useEffect(() => {
    const childrenIds = children.map(({ id }) => id);
    // add 'default' as an element to the array, this would be responsible for getting the activities of the parent swithout children
    childrenIds.push('default');
      // add 'default' as an element to the array, this would be responsible for getting the activities of the parent swithout children
    const childrenAppointments = childrenIds.map(async (childId) => {
      const { data: response } = await get(`family/appointments/${childId}`);
      return response;
    });

    Promise.all(childrenAppointments).then((appointments) => {
      dispatch(addAllAppointments(appointments));
    });
  }, [children]);

  /**
   * Get all the schedule of a child
   */
  useEffect(() => {
    const childrenIds = children.map(({ id }) => id);

    const childrenSchedule = childrenIds.map(async (childId) => {
      const { data: response } = await get(`family/custody/${childId}`);
      const { custody = JSON.stringify([]) } = response;
      return JSON.parse(custody);
    });

    Promise.all(childrenSchedule).then((schedule) => {
      dispatch(addAllCustody(schedule));
    });
  }, [children]);

  useEffect(() => {
    // only create a new socket if the user is logged in and there was no previous socket.
    // to prevent multiple socket connects per user.
    if (user && !socket) {
      const { id } = user;
      const newSocket = getSocket(id);
      // store the socket instance in state for later use
      dispatch(setSocket(newSocket));
      // notify us once the iser has been connected to the soket
      newSocket.on("connect", function () {
        console.log("socket has been connected");
      });
      newSocket.on("NEW_NOTIFICATION", function (payload) {
        const { subtype: subType } = payload;
        dispatch(addOneAlert(payload));
        dispatch(setUnseenAlertCount());
        if (subType === "FAMILY_REQUEST_ACCEPTED") {
          fetchFamily();
        }
        if (["YOUR_TURN", "NEW_GAME"].includes(subType)) {
          // refetch the state of the board
          getGameState();
        }
      });
      newSocket.on("RECIEVE_MESSAGE", (payload) => {
        dispatch(addOneMessage(payload));
      });
    }
  }, [user]);

  /**
   * Fetch the state of the class
   */
  useEffect(() => {
    if (user?.id) {
      getGameState();
    }
  }, [user]);

  /**
   * Fetch all the notifications on mount
   */
  useEffect(() => {
    // fetch all the notifications and family info
    if (user?.id) {
      dispatch(getChildren());
      get(`notification/one`)
        .then(({ data }) => {
          const parsedNotifications = JSON.parse(data.notifications);
          dispatch(setAlerts(parsedNotifications));
          dispatch(setUnseenAlertCount());
        })
        .catch((err) => {
          console.log("there was an error fetching notifications");
          console.log(err.message);
        });
      // get family info
      fetchFamily();
    }
  }, [user]);

  /**
   * Fetch chat with spouse when we get such data
   */
  useEffect(() => {
    if (user?.familyId) {
      post("message/one", {
        family: user.familyId,
      })
        .then(({ data }) => {
          dispatch(setMessages(data));
        })
        .catch((err) => {
          console.log("there was an error fetching family");
          console.log(err.message);
        });
    }
  }, [user]);

  useEffect(() => {
    // if the page is reloaded, check if theres a token and get user accordingly
    if (getToken()) {
      const userFromToken = getUser();
      // // save the user to store
      dispatch(setUser(userFromToken));
      // get recent data from api and update as well
      get("user/details")
        .then(({ data: { user, token } }) => {
          user && dispatch(setUser(user));
          token && setToken(token);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <Suspense fallback={<Loader/>}>
        <Switch>
          {user ? (
            <Route exact path="/" component={SplashIntro} />
          ) : (
            <Route exact path="/" component={LoginPage} />
          )}
          <ProtectedRoute exact path="/splash-menu" component={SplashMenu} />
          <ProtectedRoute exact path="/survey" component={SurveyPage} />
          <ProtectedRoute exact path="/message" component={MessagePage} />
          <ProtectedRoute exact path="/calendar" component={CalendarPage} />
          <ProtectedRoute
            exact
            path="/calendar/custody"
            component={CustodyPage}
          />
          <ProtectedRoute
            exact
            path="/calendar/activity"
            component={ActivityPage}
          />
          <ProtectedRoute
            exact
            path="/calendar/appointments"
            component={AppointmentsPage}
          />
          <ProtectedRoute exact path="/family" component={FamilyPage} />
          <ProtectedRoute
            exact
            path="/family/parent-profile/:userId"
            component={ParentProfilePage}
          />
          <ProtectedRoute
            exact
            path="/family/child-profile/:childId"
            component={ChildProfilePage}
          />
          <ProtectedRoute exact path="/games" component={GamesPage} />
          <ProtectedRoute exact path="/login" component={LoginPage} />
          <Route exact path="/login/:id" component={LoginPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <ProtectedRoute exact path="/alerts" component={AlertsPage} />
          <AdminRoute path="/admin" component={AdminPanel} />
        </Switch>
      </Suspense>
      <AlertMessage />
    </>
  );
};

export default Root;
