import axios from "axios";
import { getToken } from "./storage";

//export const BASE = "http://204.197.4.33:8081/";
// export const BASE = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
// 		? 'http://localhost:8081/'
// 		: 'http://204.197.4.33:8081/';

export const BASE = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
		? 'http://0.0.0.0:8081/'
		: '/api/';

export const SOCKET_BASE = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
		? 'http://0.0.0.0:8081/'
		: '/';

/**
 * dynamically get the tokens for every API call
 * @param {String} token The token which would enable the user access protected routes
 */
const getHeader = (token) => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
});

/**
 * Function to generate the profilepicture of a user
 */
export const generateProfileImage = (image) => {
  // we have migrated to cloudinay so this is not necesary
  // const imageLink = image ? `${BASE}${image}` : null;
  return image || null;
};

export const post = async (path, obj) => {
  try {
    const response = await axios.post(BASE + path, obj, {
      headers: getHeader(getToken()),
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const put = async (path, obj) => {
  try {
    const response = await axios.put(BASE + path, obj, {
      headers: getHeader(getToken()),
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const get = async (path, token=false) => {
  try {
    const response = await axios.get(BASE + path, {
      headers: getHeader(token || getToken()),
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const handleUpdateProfilePic = async (picture) => {
  const formData = new FormData();
  formData.append("profilePicture", picture[0]);
  const response = await axios.post(`${BASE}user/uploadimage`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response;
};

export const handleUpdateChildPic = async (picture, childId) => {
  console.log(picture);
  const formData = new FormData();
  formData.append("profilePicture", picture[0]);
  const response = await axios.post(
    `${BASE}family/editpicture/${childId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response;
};
