import React from 'react';
// import Logo from '../../assets/images/logo.png';
import Logo from '../../assets/images/logo_b.png';
import "./styles.scss";

export default function Index() {
    return (
        <div className="image-loader">
            <img
                src={Logo}
                alt="logo"
            />
        </div>
    )
}
