import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  surveys: {
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
    data: [],
  },
  abuse: {},
  profile: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setSurveys(state, action) {
      state.surveys = action.payload;
      return state;
    },
    setFlaggedComments(state, action) {
      state.abuse = action.payload;
      return state;
    },
    setCurrentPage(state, action) {
      state.surveys.currentPage = action.payload;
      return state;
    },
  },
});

export const { setSurveys, setFlaggedComments, setCurrentPage } = adminSlice.actions;

export default adminSlice.reducer;
