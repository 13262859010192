import React from "react";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";

import Root from "./Root";

const App = () => {
    return (
        <BrowserRouter>
            <LastLocationProvider>
                <Root />
            </LastLocationProvider>
        </BrowserRouter>
    );
};

export default App;
