import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  SEND_FAMILY_REQUEST,
  ACCEPT_FAMILY_REQUEST,
  REJECT_FAMILY_REQUEST,
  SEND_MESSAGE,
} from "@/store/events";
import { createMessage } from "@/utils/Functions";
import { setUser } from "@/store/reducers/userSlice";
import {
  setSuccessMessage,
  clearSuccessMessage,
  setErrorMessage,
  clearErrorMessage,
} from "@/store/reducers/errorSlice";

const initialState = {
  socket: null,
  messages: [],
};

const sendMessage = createAsyncThunk(
  "socket/sendMessage",
  async ({ recipientId, text }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const {
      socket: { socket },
    } = getState();

    return socket.emit(
      SEND_MESSAGE,
      { recipientId, message: createMessage(text) },
      (response) => {
        // console.log('message response')
        dispatch(addOneMessage(response.message));
      }
    );
  }
);

const sendFamilyRequest = createAsyncThunk(
  "socket/sendFamRequest",
  async (recipientId, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const {
      socket: { socket },
    } = getState();

    socket.emit(SEND_FAMILY_REQUEST, { recipientId }, (response) => {
      dispatch(setSuccessMessage(response.message));
      setTimeout(() => {
        dispatch(clearSuccessMessage());
      }, 5000);
    });
  }
);

const acceptFamilyRequest = createAsyncThunk(
  "socket/acceptFamRequest",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const {
      socket: { socket },
    } = getState();

    socket.emit(ACCEPT_FAMILY_REQUEST, payload, (response) => {
      dispatch(setSuccessMessage(response.message));
      setTimeout(() => {
        dispatch(clearSuccessMessage());
      }, 5000);
    });
  }
);

const rejectFamilyRequest = createAsyncThunk(
  "socket/acceptFamRequest",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const {
      socket: { socket },
    } = getState();

    socket.emit(REJECT_FAMILY_REQUEST, payload, (response) => {
      alert(response.message);
      dispatch(setErrorMessage(response.message));
      setTimeout(() => {
        dispatch(clearErrorMessage());
      }, 8000);
    });
  }
);

const socket = createSlice({
  name: "socket",
  initialState: initialState,
  reducers: {
    // save the socket to state
    setSocket: (state, action) => {
      console.log(action.payload);
      state.socket = action.payload;
      return state;
    },
    setMessages(state, action) {
      state.messages = action.payload;
      return state;
    },
    setFlaggedMessage(state, action) {
      console.log("setFlaggedMessage")
      const newMessages = state.messages.map(oldMsg => {
        const { id } = oldMsg;
        if(id === action.payload){
          oldMsg.flagged = !oldMsg.flagged;
        }
        return oldMsg
      });

      state.messages = newMessages
      return state
    },
    addOneMessage(state, action) {
      state.messages = [...state.messages, action.payload];
      return state;
    },
    setMessagesToSeen(state, action) {
      let alertCopy = JSON.parse(JSON.stringify(state.alerts));
      const alertLen = alertCopy.length;
      for (let index = 0; index < alertLen; index++) {
        alertCopy[index].seen = true;
      }
      state.messages = alertCopy;
      state.unseenMessage = 0;
      return state;
    },
    setUnseenMessagesCount(state, action) {
      if (action.payload === undefined) {
        let count = 0;
        const alertLen = state.alerts.length;
        for (let i = 0; i < alertLen; i++) {
          // console.log(state.alerts[i].seen)
          if (!state.alerts[i].seen) {
            count++;
          }
        }
        state.unseenMessage = count;
      } else {
        state.unseenMessage = action.payload;
      }
      return state;
    },
  },
  extraReducers: {
    [sendFamilyRequest.fulfilled]: (state, action) => {
      // console.log(action.payload);
      // console.log(action.payload)
    },
    [sendFamilyRequest.rejected]: (state, action) => {
      // console.log(action.payload);
      // console.log('rejected')
    },
    [sendMessage.fulfilled]: (state, action) => {
      // console.log('herere')
      // console.log(action.payload);
      // add it to state
      // console.log(action.payload)
    },
  },
});

const {
  setSocket,
  setMessages,
  setMessagesToSeen,
  setUnseenMessagesCount,
  addOneMessage,
  setFlaggedMessage,
} = socket.actions;

export {
  setSocket,
  sendFamilyRequest,
  acceptFamilyRequest,
  rejectFamilyRequest,
  sendMessage,
  setMessages,
  setMessagesToSeen,
  setUnseenMessagesCount,
  addOneMessage,
  setFlaggedMessage
};

export default socket.reducer;
