import { createSlice } from "@reduxjs/toolkit";
import { TILE_COUNT } from "@/pages/games/components/mainGame/constants"
import { shuffle } from "@/pages/games/components/mainGame/helpers"

const initialState = {
  gameData: {
    createdAt: null,
    id: null,
    state: shuffle([...Array(TILE_COUNT).keys()]),
    updatedAt: null,
    userId: null,
    complete: -1, // -1 - Hasn't Started, 0 - Started, 1 - Finished,
    turnsRemaining: 3 
  }
};

const gameSlice = createSlice({
  name: "game",
  initialState: initialState,
  reducers: {
    // Generic Errors for AJAX
    setTiles: (state, action) => {
      const correctTiles = Object.keys(action.payload).filter((key) => key === action.payload[key])

      if (correctTiles.length === action.payload.length) {
        state.gameData.complete = 1;
      } 
      state.gameData.state = action.payload;

      return state;
    },
    setComplete: (state, action) => {
      state.gameData.complete = action.payload;
      return state;
    },
    setGameData: (state, action) => {
      const correctTiles = Object.keys(action.payload.state).filter((key) => key === action.payload.state[key])
      state.gameData = action.payload;
      
      if (correctTiles.length === action.payload.state.length) {
        state.gameData.complete = 1;
      } 

      return state;
    }
  },
});

export const {
  setTiles,
  setComplete,
  setGameData
} = gameSlice.actions;
export default gameSlice.reducer;