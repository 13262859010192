import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { getToken, getUser } from  "@/api/storage";
import { useSelector } from "react-redux"; 

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	const loggedIn = getToken();
	
	return <Route {...rest} render={(props) => (loggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />)} />;
};

export const AdminRoute = ({ component: Component, ...rest }) => {
	const loggedIn = getToken();
	const { admin } = getUser();
	// const 
	return <Route {...rest} render={(props) => ((loggedIn && admin) ? <Component {...props} /> : <Redirect to={{ pathname: '/family' }} />)} />;
};