import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alerts: [],
  unseenAlertCount: 0,
};

// const initialState = {
//   alerts: [
//     {
//       type: "Game",
//       text: "It's your turn.",
//       createdDate: JSON.stringify(new Date()),
//       seen: false
//     },
//     {
//         type: "Calendar",
//         text: "New activity added.",
//         createdDate: JSON.stringify(new Date()),
//         seen: false
//       },
//       {
//         type: "Calendar",
//         text: "Appointment updated.",
//         createdDate: JSON.stringify(new Date()),
//         seen: true
//       },
//       {
//         type: "Game",
//         text: "It's your turn.",
//         createdDate: JSON.stringify(new Date()),
//         seen: true
//       },
//       {
//           type: "Calendar",
//           text: "New activity added.",
//           createdDate: JSON.stringify(new Date()),
//           seen: true
//         },
//         {
//           type: "Calendar",
//           text: "Appointment updated.",
//           createdDate: JSON.stringify(new Date()),
//           seen: true
//         },
//   ],
//   unseenAlertCount: 2
// };

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlerts(state, action) {
      state.alerts = action.payload;
      return state;
    },
    addOneAlert(state, action) {
      state.alerts = [action.payload, ...state.alerts];
      return state;
    },
    setAlertsToSeen(state, action) {
      let alertCopy = JSON.parse(JSON.stringify(state.alerts));
      const alertLen = alertCopy.length;
      for (let index = 0; index < alertLen; index++) {
        alertCopy[index].seen = true;
      }
      state.alerts = alertCopy;
      return state;
    },
    setUnseenAlertCount(state, action) {
      if (action.payload === undefined) {
        let count = 0;
        const alertLen = state.alerts.length;
        for (let i = 0; i < alertLen; i++) {
          // console.log(state.alerts[i].seen)
          if (!state.alerts[i].seen) {
            count++;
          }
        }
        state.unseenAlertCount = count;
      } else {
        state.unseenAlertCount = action.payload;
      }
      return state;
    },
  },
});

export const {
  setAlerts,
  setAlertsToSeen,
  setUnseenAlertCount,
  addOneAlert,
} = alertSlice.actions;

export default alertSlice.reducer;
