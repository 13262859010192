import { getUser } from "@/api/storage";
import moment from "moment";
import { parse } from "query-string";

export const setLocalStorage = (identifier, payload) => {
  localStorage.setItem(identifier, JSON.stringify(payload));
};

export const clearLocalStorage = (identifier) => {
  localStorage.removeItem(identifier);
};

export const getLocalStorage = (identifier) => {
  const info = localStorage.getItem(identifier);
  if (!!info) {
    return JSON.parse(info);
  } else {
    return null;
  }
};

/**
 * A utility function used to partition an array by a particular index
 * @param {Array} array a list containing elements
 * @param {Number} splitIndex a number representing the index of the element we wish to partition from
 * @returns 
 */
export const splitArray = (array, splitIndex) => {
  if(!splitIndex) return array;
  const aPart = array.slice(0, splitIndex);
  const bPart = array.slice(splitIndex,);
  return [...bPart, ...aPart]
};


/**
 * A function used to convert a twelve bour string into twentyhour format
 * @param {String} twelveHourString a twelve hour representation of a time e.g 02:00 pm
 * @returns {Object} an object containing the components of minute and hour of a particular time e.g {hour, minute, totalMinutes}
 */
export const convertTo24Hours = (twelveHourString) => {
  const twentyfourHourString = moment(twelveHourString, ["h:mm A"]).format("HH:mm");
  const [hour, minute] =  twentyfourHourString.split(':');
  const totalMinutes = (parseInt(hour) * 60 )+ parseInt(minute);
  return {hour: parseInt(hour), minute: parseInt(minute), totalMinutes };
}

/**
 * Helper to create a message item to send
 * @param {*} text The text which is the content of the message
 */
export const createMessage = (text) => ({
  text,
  senderId: getUser().id,
  createdDate: Date.now(),
  seen: false,
});

/**
 * Convert a unix timestamp to datetime format
 * @param {*} timestamp Time stamp of the message
 */
export const parseTextDate = (timestamp) => {
  const momentObj = moment(timestamp);
  return momentObj.format("MMM/DD/YYYY, hh:mm A");
};

/**
 * Use this function to parse a json string without errors.
 * @param {String} JSONString the json string which we want to parse.
 * @param {Boolean} dict are we parsing a dict or list JSON.
 */
export const parseJson = (JSONString, dict) => {
  try{
    return JSON.parse(JSONString)
  }catch(err){
    return dict? {} : []
  }
}

/**
 * a function used to convert a time integer into minutes in order to foster better comparison
 * @param {String} hourTimeString a string of the format hh:mm
 */
export const convertTimeStringToMinute = (hourTimeString) => {
  const parsedHour = Number(hourTimeString.split(":")[0]);
  const parsedMinute = Number(hourTimeString.split(":")[1]?.split(' ')[0] || 0);

  return (parsedHour * 60 ) + parsedMinute 
}

export const formattedDate = (dateObj = false) => {
  const momentObj = moment(dateObj ? new Date(dateObj) : Date.now());
  return momentObj.format("LL");
};

export const formattedScheduleHeaderDate = (dateObj = false) => {
  const momentObj = dateObj ? moment(dateObj) : moment(Date.now());
  return `${momentObj.format("MMMM")} ${momentObj.format("YYYY")}`;
}

export const thisWeekArr = (dateObj = false) => {
  let startOfWeek;
  let endOfWeek;

  if (dateObj){
    startOfWeek = moment(dateObj).startOf("week");
    endOfWeek = moment(dateObj).endOf("week");
  } else {
    startOfWeek = moment().startOf("week");
    endOfWeek = moment().endOf("week");
  }


  const days = [];
  let day = startOfWeek;

  while (day <= endOfWeek) {
    days.push(day.toDate());
    day = day.clone().add(1, "d");
  }

  return days;
};


export const minusWeek = (dateObj) => {
  return moment(dateObj).subtract(1, 'week')
}


export const addWeek = (dateObj) => {
  return moment(dateObj).add(1, 'week')
}