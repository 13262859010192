import { combineReducers } from "@reduxjs/toolkit";
import adminSlice from "./adminSlice";
import alertSlice from "./alertSlice";
import errorSlice from "./errorSlice";
import taskSlice from "./taskSlice";
import userSlice from "./userSlice";
import gameSlice from "./gameSlice";
import socketSlice from "./socketSlice";

const rootReducer = combineReducers({
    socket: socketSlice,
    admin: adminSlice,
    alerts: alertSlice,
    error: errorSlice,
    tasks: taskSlice,
    user: userSlice,
    game: gameSlice,
});

export default rootReducer;
