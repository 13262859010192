import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "@/api/index";

const initialState = {
  userData: null,
  spouseData: null,
  children: [],
  activeChild: [],
  childActivities: [],
  childAppointments: [],
  childCustody: [],
};

/**
 * Thunk to get all children in a family
 */
const getChildren = createAsyncThunk(
  "user/fetchChildren",
  async (payload, thunkAPI) => {
    const { data } = await get("family/children");
    return data;
  }
);

/**
 * Thunk to add a child to a family
 */
const addChild = createAsyncThunk(
  "user/addChild",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await get("family/addchild");
    dispatch(getChildren());
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    // Generic Errors for AJAX
    logoutUser: (state) => {
      state.userData = null;
      return state;
    },
    setUser: (state, action) => {
      if (action && action.payload) {
        state.userData = action.payload;
      }
      return state;
    },
    setSpouse: (state, action) => {
      if (action && action.payload) {
        state.spouseData = action.payload;
      }
      return state;
    },
    setActiveChild: (state, action) => {
      state.activeChild = action.payload;
      return state;
    },
    incrementChildcount: (state, action) => {
      const oldIndex = state.activeChild;
      const noChildren = state.children.length;

      const newIndex = (oldIndex + 1) % noChildren;
      state.activeChild = newIndex;
      return state;
    },
    addAllActivities: (state, action) => {
      if (action && action.payload) {
        state.childActivities = action.payload;
        return state;
      }
    },
    addAllAppointments: (state, action) => {
      if (action && action.payload) {
        state.childAppointments = action.payload;
        return state;
      }
    },
    addAllCustody: (state, action) => {
      if (action && action.payload) {
        state.childCustody = action.payload;
        return state;
      }
    },
  },
  extraReducers: {
    [getChildren.fulfilled]: (state, action) => {
      if (action && action.payload) {
        state.children = action.payload;
      }
      return state;
    },
  },
});

const {
  logoutUser,
  setUser,
  setSpouse,
  incrementChildcount,
  addAllActivities,
  addAllAppointments,
  addAllCustody,
  setActiveChild,
} = userSlice.actions;

export {
  logoutUser,
  setUser,
  setSpouse,
  addChild,
  getChildren,
  incrementChildcount,
  addAllActivities,
  addAllAppointments,
  addAllCustody,
  setActiveChild,
};
export default userSlice.reducer;
